import { createWebHistory, createRouter } from "vue-router";
// import axios from 'axios';
import routes from './routes'
import appConfig from "../../app.config";
import axios from "axios";
import useGlobalData from "@/state/global-data";
// import useGlobalData from "@/state/global-data";
// import VueCookies from "vue-cookies";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, left: 0 }
    }
  },
})

// Before each route evaluates...
let lastPath = null;
router.beforeEach(async (routeTo, routeFrom, next) => {

  // Handle impersonation
  // const manageSession = VueCookies.get('manage-session');
  // if (manageSession) {
  //   let domain = null;
  //   if (process.env.VUE_APP_STAGE === 'staging') {
  //     domain = "staging.bodyguard.io";
  //   } else if (process.env.VUE_APP_STAGE === 'production') {
  //     domain = "prod.bodyguard.io";
  //   }
  //   VueCookies.remove('manage-session', "/", domain);
  //   localStorage.setItem('jwt', manageSession);
  //   return next({name: "default"});
  // }

  // Store the current path
  const currentPath = routeTo.path;
  console.log(currentPath);
  // Check if the current path is the same as the last path, implying a hash change
  if (currentPath === lastPath) {
    // Skip the navigation guard logic
    return next();
  }
  // Update lastPath
  lastPath = currentPath;

  // Authentication not required
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  if (!authRequired) return next()
  if (!localStorage.getItem('jwt')) {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }

  // Require authentication
  axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt') // for all requests
  axios.defaults.headers.common['x-auth-token'] = localStorage.getItem('jwt') // for all requests
  await axios.get(process.env.VUE_APP_API_BASE_URL+'/api/portal/auth/info').then((data) => {
    localStorage.setItem('userdata', JSON.stringify(data.data.user))
    localStorage.setItem('userid', data.data.user._id)
    const globalData = useGlobalData()
    globalData.setUserEmail(data.data.user.email);
    globalData.setUserMembership(data.data.user.membership);
    globalData.setCredits(data.data.user.credits);
    next()
  }).catch(async (error) => {
    if(error.response) {
      // console.log(error.response.data)
      // console.log(error.response.status)
      if (error.response.status === 403 ||
          error.response.status === 400) {
        // Forbidden or malformed
        localStorage.removeItem('jwt')
        next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
        return;
      }
    }
    // Network error
    // await new Promise(resolve => setTimeout(resolve, 2000));
    // Redirecting to login with jwt set, results in continuous loop via router.
    // Therefore we direct to offline page
    next({ name: 'offline', query: { redirectFrom: routeTo.fullPath } })
  });
  next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }
  if (typeof routeTo.meta.title !== "undefined") {
    document.title = routeTo.meta.title + ' | ' + appConfig.title;
  } else {
    document.title = appConfig.title;
  }
  // If we reach this point, continue resolving the route.
  next()
})

export default router
